import React, { Component, useEffect } from 'react';
import './assets/style.css';
import Dashboard from './views/dashboard/Userdash/Dashboard.js';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

// MBA Components
import Status from './views/mba/Status.js';
import ResponseRules from './views/mba/ResponseRules';
import ResponseRulesDetail from './views/mba/ResponseRulesDetail';
import Clusters from './views/mba/Clusters';
import Messages from './views/mba/Messages';
import MBALog from './views/mba/Log';
import StatusDetail from './views/mba/StatusDetail';
import TimeTables from './views/mba/Timetables';

// Careapp interface Components
import NotFound from './views/NotFound.js';
import Login from './views/Login.js';
import OpenAlarms from './views/dashboard/Userdash/OpenAlarms.js';
import HistoryAlarms from './views/dashboard/Userdash/HistoryAlarms.js';
import AlarmDetail from './views/dashboard/Userdash/AlarmDetail.js';
import Employees from './views/dashboard/Userdash/Employees.js';
import EmployeesTeam from './views/dashboard/Userdash/EmployeesTeam.js';
import ReportHistoryAlarmsTech from './views/dashboard/Reports/HistoryAlarmsTech';
import ReportHistoryAlarms from './views/dashboard/Reports/HistoryAlarms';
import ReportHistoryAlarmDetails from './views/dashboard/Reports/HistoryAlarmDetails';
import ReportHistoryAlarmTechDetails from './views/dashboard/Reports/HistoryAlarmTechDetails';
import ReportAlarmLogging from './views/dashboard/Reports/AlarmLogging';
import ReportEmployees from './views/dashboard/Reports/Employees';
import Roomunits from './views/dashboard/Reports/Roomunits';
import ReportWelfare from './views/dashboard/Reports/Welfare';
import ReportReceives from './views/dashboard/Reports/Receives';
import AlarmPerformance from './views/dashboard/Reports/AlarmPerformance';
import PhonebookInternal from './views/dashboard/Phonebook/PhonebookInternal';
import PhonebookExternal from './views/dashboard/Phonebook/PhonebookExternal';

// Manage modules
import Organisation from './views/manage/Organisation';
import Settings from './views/manage/Settings';
import SettingsVideo from './views/manage/Video';
import SettingsRooms from './views/manage/Rooms';
import SettingsTransmitters from './views/manage/Transmitters';
import SettingsRoomDetails from './views/manage/RoomDetails';
import OrganisationItems from './views/manage/OrganisationItems';
import DeviceTypes from './views/manage/DeviceTypes';
import Teams from './views/manage/Teams';
import TeamRoutes from './views/manage/Routes';
import TeamRouteDetails from './views/manage/RouteDetails';
import WelfareSettings from './views/manage/WelfareSettings.js';
import OrganisationDetails from './views/manage/OrganisationDetails';
import DeviceDetails from './views/manage/DeviceDetails';
import Devices from './views/manage/Devices';
import TeamDetails from './views/manage/TeamDetails';
import WelfareDetails from './views/manage/WelfareDetails';
import VitualTourSettings from './views/manage/VirtualTourSettings'
import Modules from './views/manage/Modules';
import ModuleDetail from './views/manage/ModuleDetail';
import Logging from './views/manage/Logging';
import Permissions from './views/manage/Permissions';
import PermissionsDetail from './views/manage/PermissionsDetail';
import ModulesCategories from './views/manage/ModulesCategories';
import Tracking from './views/manage/Tracking';
import TrackingDetail from './views/manage/TrackingDetail';
import Languages from './views/manage/Languages';
import LanguageDetails from './views/manage/LanguageDetails';
import NightNurseDetail from './views/manage/NightNurseDetail';
import RFID from './views/manage/RFID';
import Upload from './views/manage/Upload';
import Tips from './views/manage/Tips';
import NightNurse from './views/manage/NightNurse';

import VirtualTour from './views/dashboard/Userdash/VirtualTour.js';
import BI from './components/BI';
import Hikvision from './views/dashboard/Hikvision';
import NoAccess from './views/NoAccess.js';
import NeckStatus from './views/dashboard/Reports/StatusNeck';
import Heartbeats from './views/dashboard/Reports/Heartbeats';
import Notifications from './views/manage/Notifications.js';

import Editor from './components/Dashboard/Editor';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import SiebarManage from './components/ManageSidebar.js';

import ActivityReport from './views/dashboard/Reports/ActivityReport';

import EmployeeDash from './views/dashboard/Reports/EmployeeDash';
import ObjectStatus from './views/manage/ObjectStatus';
import EmployeeDashList from './views/dashboard/Reports/EmployeeDashList';

import { checkRefreshToken, getBlyottToken, getKeplerClient, getToken, fetchApi } from './Functions';

import ResponseRulesAdd from './views/mba/ResponseRulesAdd';
import MessageDetail from './views/mba/MessageDetail';
import TimetablesDetail from './views/mba/TimetablesDetail';

import i18next from 'i18next';
import VirtualRound from './views/dashboard/Userdash/VirtualRound.js';
import RoomInsights from './views/dashboard/Reports/RoomInsights';
import RoomInsightsList from './views/dashboard/Reports/RoomInsightsList';
import Templates from './views/mba/Templates.js';
import TemplateDetails from './views/mba/TemplateDetails.js';

import PhonebookDetails from './views/dashboard/Phonebook/PhonebookDetails.js';
import TipsDetails from './views/manage/TipsDetails.js';

const App = () => {

  const getLanguage = async (userid) => {
    const getUserConfig = await fetchApi("mba", `/mba/organization/detailconfig/${userid}`, "GET", {
      "objectid": userid
    })
    var countrycode = 'en';

    getUserConfig.forEach(conf => {
      if(conf.name === "Language"){
        countrycode = conf.value;
      }
    })

    localStorage.setItem('Language', countrycode);
    i18next.changeLanguage(countrycode);
  }

  const checkRoleSpecifics = async (roleid) => {
    const role = await fetchApi("mba", `/mba/modules/roles/get/${roleid}`, "GET", {
      "roleid": roleid
    })
   
    return {
      manager: role[0].ismanager == 1 ? true : false,
      admin: role[0].isadmin == 1 ? true : false
    }
  }

  useEffect(() => {
    const role = localStorage.getItem("Role");
    const rolesToCheck = ["1", "2", "5"];
    const token = localStorage.getItem('nightnursetoken');
    
    if ((rolesToCheck.includes(role)) && (!token || token === '')) {
        getToken();
    }
  
    setInterval(() => {
        if (rolesToCheck.includes(role)) {
            checkRefreshToken();
        }
    }, 5000)

    if(localStorage.getItem("Role") == "2" || localStorage.getItem("Role") == "5" && !localStorage.getItem('blyot_token') || (localStorage.getItem('blyot_token') && localStorage.getItem('blyot_token') === '')){
      getBlyottToken();
    }

    if(localStorage.getItem("Role") == "2" || localStorage.getItem("Role") == "5" && !localStorage.getItem('nightnursetoken') || (localStorage.getItem('nightnursetoken') && localStorage.getItem('nightnursetoken') === '')){
      getToken();
    }

    if(localStorage.getItem("id_token")){
      getLanguage(localStorage.getItem('UserID'));
    }
  }, [])

  var userRole = localStorage.getItem("Role");
  
  const currentRole = checkRoleSpecifics(userRole).then((role) => {
    var url = window.location.href.split("/");
    if(url[3] == 'manage' && role.admin == false){
      window.location.href = "/no-access";
    }
    if(url[3] == 'reports' && role.admin == false && role.manager == false){
      window.location.href = "/no-access";
    }
  });

    return (
      <div className="App">
        <Router>
          <React.Fragment>
            { localStorage.getItem('id_token') ? <Header /> : ''}
            <div>
              { localStorage.getItem('id_token') && window.location.href.split("/")[3] !== "manage" ? <Sidebar /> : (localStorage.getItem('id_token') ? <SiebarManage /> : '') }
              <div className="content">
                <Routes>
                  {localStorage.getItem('id_token') && localStorage.getItem('id_token') !== '' ? <Route exact="true" path="/" element={<Dashboard />} /> : <Route exact="true" path="/" element={<Login />} />}
                  <Route exact="true" path="/mba-admin/status" element={<Status />} />
                  <Route exact="true" path="/mba-admin/status/:id" element={<StatusDetail />} />
                  <Route exact="true" path="/mba-admin/responserules" element={<ResponseRules />} />
                  <Route exact="true" path="/mba-admin/responserules/:id" element={<ResponseRulesDetail />} />
                  <Route exact="true" path="/mba-admin/responserules/insert" element={<ResponseRulesAdd />} />
                  <Route exact="true" path="/mba-admin/responserules/clusters" element={<Clusters />} />
                  <Route exact="true" path="/mba-admin/messages" element={<Messages />} />
                  <Route exact="true" path="/mba-admin/messages/:id" element={<MessageDetail />} />
                  <Route exact="true" path="/mba-admin/log/:date" element={<MBALog />} />
                  <Route exact="true" path="/mba-admin/log/:date/:date2" element={<MBALog />} />
                  <Route exact="true" path="/mba-admin/timetables" element={<TimeTables />} />
                  <Route exact="true" path="/mba-admin/timetables/:id" element={<TimetablesDetail />} />
                  <Route exact="true" path="/mba-admin/responserules/templates" element={<Templates />} />
                  <Route exact="true" path="/mba-admin/responserules/templates/:id" element={<TemplateDetails />} />

                  <Route exact="true" path="/manage/objectstatusses" element={<ObjectStatus />} />
                  <Route exact="true" path="/manage/organisation/upload" element={<Upload />} />
                  <Route exact="true" path="/manage/organisation/" element={<Organisation />} />
                  <Route exact="true" path="/manage/organisation/object/:id" element={<OrganisationItems />} />
                  <Route exact="true" path="/manage/organisation/details/:id/:objectid" element={<OrganisationDetails />} />
                  <Route exact="true" path="/manage/devices/" element={<DeviceTypes />} />
                  <Route exact="true" path="/manage/devices/:id" element={<DeviceDetails />} />
                  <Route exact="true" path="/manage/devices/object/:id" element={<Devices />} />
                  <Route exact="true" path="/manage/teams" element={<Teams />} />
                  <Route exact="true" path="/manage/team/:id" element={<TeamDetails />} />
                  <Route exact="true" path="/manage/teamroutes" element={<TeamRoutes />} />
                  <Route exact="true" path="/manage/teamroute/:id" element={<TeamRouteDetails />} />
                  <Route exact="true" path="/manage/tips" element={<Tips />} />
                  <Route exact="true" path="/manage/tips/:id" element={<TipsDetails />} />
                  <Route exact="true" path="/manage/rfid" element={<RFID />} />
                  <Route exact="true" path="/manage/welfaresettings" element={<WelfareSettings />} />
                  <Route exact="true" path="/manage/welfaresettings/:id" element={<WelfareDetails />} />
                  <Route exact="true" path="/manage/nightnurse" element={<NightNurse />} />
                  <Route exact="true" path="/manage/nightnurse/:id" element={<NightNurseDetail />} />
                  <Route exact="true" path="/manage/logging" element={<Logging />} />
                  <Route exact="true" path="/manage/languages" element={<Languages />} />
                  <Route exact="true" path="/manage/languages/:id" element={<LanguageDetails />} />
                  <Route exact="true" path="/manage/video" element={<SettingsVideo />} />
                  <Route exact="true" path="/manage/settings" element={<Settings />} />
                  <Route exact="true" path="/manage/rooms" element={<SettingsRooms />} />
                  <Route exact="true" path="/manage/transmitters" element={<SettingsTransmitters />} />
                  <Route exact="true" path="/manage/virtualtour/settings" element={<VitualTourSettings />} />
                  <Route exact="true" path="/manage/rooms/:id" element={<SettingsRoomDetails />} />
                  <Route exact="true" path="/manage/modules" element={<Modules />} />
                  <Route exact="true" path="/manage/modules/categories" element={<ModulesCategories />} />
                  <Route exact="true" path="/manage/modules/:id" element={<ModuleDetail />} />
                  <Route exact="true" path="/manage/permissions" element={<Permissions />} />
                  <Route exact="true" path="/manage/permissions/role/:id" element={<PermissionsDetail />} />
                  <Route exact="true" path="/manage/tracking" element={<Tracking />} />
                  <Route exact="true" path="/manage/tracking/:mac" element={<TrackingDetail />} />
                  <Route exact="true" path="/manage/notifications" element={<Notifications />} />
                  
                  <Route exact="true" path="/editor" element={<Editor />} />
                  <Route exact="true" path="/hikvision" element={<Hikvision />} />
                  <Route exact="true" path="/alarms/open" element={<OpenAlarms />} />
                  <Route exact="true" path="/alarms/history" element={<HistoryAlarms />} />
                  <Route exact="true" path="/alarm/:id" element={<AlarmDetail />} />
                  <Route exact="true" path="/employees" element={<Employees />} />
                  <Route exact="true" path="/employees/team/:id" element={<EmployeesTeam />} />
                  <Route exact="true" path="/virtualtour" element={<VirtualTour />} />
                  <Route exact="true" path="/virtualround" element={<VirtualRound />} />
                  <Route exact="true" path="/reports/historyalarmstech/:reportdate" element={<ReportHistoryAlarmsTech />} />
                  <Route exact="true" path="/reports/historyalarmstech/alarm/:id" element={<ReportHistoryAlarmTechDetails />} />
                  <Route exact="true" path="/reports/historyalarms/:reportdate" element={<ReportHistoryAlarms />} />
                  <Route exact="true" path="/reports/historyalarms/alarm/:id" element={<ReportHistoryAlarmDetails />} />
                  <Route exact="true" path="/reports/performance/:reportdate" element={<AlarmPerformance />} />
                  <Route exact="true" path="/reports/performance/:reportdate/:reportdate2" element={<AlarmPerformance />} />
                  <Route exact="true" path="/reports/alarmlogging/:reportdate" element={<ReportAlarmLogging />} />
                  <Route exact="true" path="/reports/activity/:reportdate" element={<ActivityReport />} />
                  <Route exact="true" path="/reports/employees/:reportdate" element={<ReportEmployees />} />
                  <Route exact="true" path="/reports/employeedash/" element={<EmployeeDashList />} />
                  <Route exact="true" path="/reports/employeedash/:id/:reportdate" element={<EmployeeDash />} />
                  <Route exact="true" path="/reports/roomunits/" element={<Roomunits />} />
                  <Route exact="true" path="/reports/neckstatus/" element={<NeckStatus />} />
                  <Route exact="true" path="/reports/welfare/:reportdate" element={<ReportWelfare />} />
                  <Route exact="true" path="/reports/heartbeats" element={<Heartbeats />} />
                  <Route exact="true" path="/reports/roominsights" element={<RoomInsightsList />} />
                  <Route exact="true" path="/reports/roominsights/:id/:reportdate" element={<RoomInsights />} />
                  <Route exact="true" path="/manage/reports/receives/:reportdate" element={<ReportReceives />} />
                  <Route exact="true" path="/phonebook/internal" element={<PhonebookInternal />} />
                  <Route exact="true" path="/phonebook/external" element={<PhonebookExternal />} />
                  <Route exact="true" path="/phonebook/contact/:id" element={<PhonebookDetails />} />
                  
                  <Route exact="true" path="/login" element={<Login />} />
                  <Route exact="true" path="/docs/bi" element={<BI />} />
                  <Route exact="true" path="/no-access" element={<NoAccess />} />
                  <Route exact="true" path="/not-found" element={<NotFound />} />
                  <Route element={<NotFound />} />
                </Routes>
              </div>
            </div>
          </React.Fragment>
        </Router>
      </div>
    );
}

export default App;