import DataTable from 'datatables.net-dt';
import { fetchApi } from '../../Functions';
const $ = require('jquery');

const checkRoleSpecifics = async (roleid) => {
    const role = await fetchApi("mba", `/mba/modules/roles/get/${roleid}`, "GET", {
      "roleid": roleid
    })
   
    return {
      hasmba: role[0].hasmba == 1 ? true : false
    }
  }

export const checkMBAUserPermission = () => {
    var userRole = localStorage.getItem("Role");
    const currentRole = checkRoleSpecifics(userRole).then((role) => {
        if(role.hasmba == false){
            window.location.href = "/no-access";
        }
    });
}

export const initDataTable = () => {
    let table = new DataTable('#DataTable');
    table.destroy();  
    setTimeout(() => {  
        table = new DataTable('#DataTable', {
            "language": {
                "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
            },
            dom: 'Bfrtip',
            "aaSorting": []
        });
    }, 500)
}

// export const initDataTable = () => {
//     $('#DataTable').DataTable({
//         "language": {
//             "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
//         },
//         "processing": true,
//         "aaSorting": []
//     });
// }

export const CutOnLength = ( str, limit ) => {
	return new String(str).substr( 0, new String(str).lastIndexOf( ' ', limit ) ) + '...';
}

export const confirmDelete = (yesCallback, noCallback) => {
    $('.modalTitle').html("Weet je zeker dat je dit wilt verwijderen?");
    $('#modal_dialog').show();

    $('#btnYes').on('click', function () {
        $('#modal_dialog').hide()
        if (yesCallback) {
            yesCallback();
        }
    });
    $('#btnNo').on('click', function () {
        $('#modal_dialog').hide()
        if (noCallback) {
            noCallback();
        }
    });
}

export const confirmDuplicate = (yesCallback, noCallback) => {
    $('.modalTitle').html("Weet je zeker dat je dit item wilt dupliceren?");
    $('#modal_dialog').show();

    $('#btnYes').on('click', function () {
        $('#modal_dialog').hide()
        if (yesCallback) {
            yesCallback();
        }
    });
    $('#btnNo').on('click', function () {
        $('#modal_dialog').hide()
        if (noCallback) {
            noCallback();
        }
    });
}

export const crudAlertControl = (action, data) => {
    if(action === 'add'){
        $('.alert-add').css('display', 'none');
        $('.alert-delete').css('display', 'none');
        $('.alert-danger').css('display', 'none');

        if(data === "OK" || data.length > 0){
            $('.alert-danger').css('display', 'none');
            $('.alert-add').fadeIn(1000);
        }else{
            $('.alert-danger').fadeIn(1000);
            $('.alert-add').css('display', 'none');
        }
    }else if(action === 'delete'){
        $('.alert-add').css('display', 'none');
        $('.alert-danger').css('display', 'none');
        $('.alert-delete').css('display', 'none');

        if(data === "OK"){
            $('.alert-danger').css('display', 'none');
            $('.alert-delete').fadeIn(1000);
        }else{
            $('.alert-danger').fadeIn(1000);
            $('.alert-delete').css('display', 'none');
        }
    }else if (action === 'update'){
        $('.alert-update').css('display', 'none');
        $('.alert-danger').css('display', 'none');

        if (data === "OK" || data.length > 0) {
            $('.alert-danger').css('display', 'none');
            $('.alert-update').css('display', 'block');
        } else {
            $('.alert-danger').css('display', 'block');
            $('.alert-update').css('display', 'none');
        }
    }else if (action === 'duplicate'){
        $('.alert-dup').css('display', 'none');
        $('.alert-add').css('display', 'none');
        $('.alert-update').css('display', 'none');
        $('.alert-danger').css('display', 'none');

        if (data === "OK" || data.length > 0) {
            $('.alert-danger').css('display', 'none');
            $('.alert-dup').css('display', 'block');
        } else {
            $('.alert-danger').css('display', 'block');
            $('.alert-dup').css('display', 'none');
        }
    }
}