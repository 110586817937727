import React, { Component, useEffect, useState } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import * as moment from 'moment';
import { initDataTable, filterDataTable, fillDataTableFilters } from "../../../Functions.js";
import LocationAlert from "../../../components/LocationAlert.js";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Export from '../../../components/Export.js';
import Filter from '../../../components/Filter';
registerLocale('nl', nl);
moment.locale('nl');

const ReportEmployees = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [loadingScreen, setLoadingScreen] = useState(true);

    const getLoggedInHistory = async (date) => {
        const functions = await import("../../../Functions.js");
        
        const data = await functions.fetchApi("mba", "/mba/report/employee", "GET", {
            "reportdate": date,
        });

        await Promise.all(data.map(async (employee, index, receive) => {
            const teams = await functions.fetchApi("mba", `/mba/report/employee/${employee.id}/teams`, "GET", {
                "employeeid": employee.id,
                "logindate": moment(employee.logintime).format('YYYY-MM-DD HH:mm:ss'),
                "logoutdate": moment(employee.logouttime).format('YYYY-MM-DD HH:mm:ss'),
                "reportdate": date,
            })
            
            employee['teams'] = teams;
        }))

        setEmployees(data);
        initDataTable();
        setLoadingScreen(false);
    }

    const goToDate = (date) => {
        setLoadingScreen(true);
        navigate('/reports/employees/' + moment(date).format('YYYY-MM-DD'));
        getLoggedInHistory(moment(date).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        getLoggedInHistory(props.router.params.reportdate);
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>

                <h1>{t('loginhistory')}</h1>

                {loadingScreen && (<div className="loadingScreen"><div className="loader"></div></div>)}
                    <div className="filters">
                        <Filter type="date" text={t('date')} callback={(data, e) => goToDate(data)} />
                        <Filter type="select" text={t('team')} id="EmployeesTeams" column="2" item="reporthistoryalarm-employeesteams" exact={false} />
                        <Export />
                    </div>

                
                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" clclassNameass="showHead table responsive" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('user')}</th>
                                <th>{t('device')}</th>
                                <th style={{ width: '300px' }}>{t('teams')}</th>
                                <th>{t('location')}</th>
                                <th>{t('loggedin')}</th>
                                <th>{t('loggedout')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee, i) => {
                                return (
                                    <tr>
                                        <td>{employee.employee}</td>
                                        <td>{employee.device}</td>
                                        <td>
                                          {employee.teams.map((team, i) => {
                                                fillDataTableFilters(document.getElementById('EmployeesTeams'), team.teamname);
                                                return (<span className="badge-pill badge-info"><div className='alarm-status' style={{ background: team.color }}></div> {team.teamname}</span>)
                                          })}
                                        </td>
                                        <td>{employee.location}</td>
                                        <td>{employee.logintime && employee.logintime !== "0000-00-00 00:00:00" ? moment(employee.logintime).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                        <td>{employee.logouttime && employee.logouttime !== "0000-00-00 00:00:00" ? moment(employee.logouttime).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(ReportEmployees);