import React, { Component, useEffect, useState } from 'react';
import '../../assets/style.css';
import withRouter from '../../components/withRouter';
import { crudAlertControl, fetchApi } from '../../Functions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ObjectLocation from '../../components/ObjectLocation.js';

const DeviceDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [device, setDevice] = useState([]);
    const [locations, setLocations] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [statusses, setStatusses] = useState([]);
    const [keplerhardwarenodes, setKeplerhardwarenodes] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [streams, setStreams] = useState([]);

    const switchRoom = async (deviceid, devicename, location) => {
        if (alarms.length === 0) {
            hideError();
            const data = await fetchApi("mba", `/mba/device/${deviceid}/update/field`, "GET", {
                "deviceid": deviceid, "name": devicename, "field": "ObjectID", "value": location
            });
        } else {
            showError(t('roomalarmserror'));
        }
    }

    const checkConfigField = async (checkfield, parametername, parametervalue) => {
        return fetchApi("mba", "/mba/device/config/check", "GET", {
            "checkfield": checkfield, 'parametername': parametername, 'parametervalue': parametervalue,
        })
    }

    const updateDeviceConfField = async (deviceid, name, paramid, value) => {
        return fetchApi("mba", `/mba/device/${deviceid}/conf/update/field`, "GET", {
            "deviceid": deviceid, "name": name, "paramid": paramid, "value": value
        })
    }

    const changeData = async (event) => {
        event.preventDefault();
        const data = await fetchApi("mba", `/mba/device/${device.deviceid}/update/field`, "GET", {
            "deviceid": device.deviceid, "name": device.evicename, "field": "Name", "value": event.target.name.value
        });
        switchRoom(device.deviceid, device.devicename, event.target.location.value);

        const target = event.target;
        const paramData = {};

        for(let i = 0; i < target.length; i++){
            if(target.elements[i].getAttribute('data-param') && target.elements[i].getAttribute('data-param') !== ''){
                if(target.elements[i].getAttribute('name') == 'MAC' && target.elements[i].getAttribute('data-param') == 6){    
                    const currenttag = await fetchApi("blyott", `/tags/get/${target.elements[i].value}`, "GET");

                    if(currenttag.found === false){
                        const insert = await fetchApi("blyott", "/tags/insert", "POST", {
                            "userid": localStorage.getItem('UserID'), "tagid": "00A050" + target.elements[i].value, "asset": props.router.params.id, "name": event.target.name.value
                        });
                    }
                }

                paramData[target.elements[i].getAttribute('name')] = target.elements[i].value;
                updateDeviceConfField(device.deviceid, device.devicename, target.elements[i].getAttribute('data-param'), target.elements[i].value);
            }

            if(data === "OK" || data.length > 0){
                crudAlertControl('update', data);
            }
        }
    }

    const showError = (text) => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = text;
        errMsgElement.style.display = 'block';
    }

    const hideError = () => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = '';
        errMsgElement.style.display = 'none';
    }

    const getData = async () => {
        const [data, params, location, status, getkeplerhardwarenodes, gethardwarenodes] = await Promise.all([
            fetchApi("mba", `/mba/device/get/${props.router.params.id}`, "GET", { "locationid": localStorage.getItem('currentLocation') }),
            fetchApi("mba", `/mba/device/config/get/${props.router.params.id}`, "GET"),
            fetchApi("mba", "/mba/object/get", "GET", { "locationid": localStorage.getItem('currentLocation') }),
            fetchApi("mba", "/mba/device/status", "GET"),
            fetchApi("kepler", "/nightnurse/hardwarenodes", "GET"),
            fetchApi("mba", "/mba/devices/hardwarenodes", "GET", { "locationid": localStorage.getItem('currentLocation') })
        ])

        if(localStorage.getItem('currentLocation')){
            const conf = await fetchApi("mba", `/mba/organization/detailconfig/${localStorage.getItem("currentLocation")}`, "GET", {
                "objectid": localStorage.getItem("currentLocation")
            });
            const hasKepler = conf.filter(param => param.name === "KeplerID");

            const locationStreams = await Promise.all(gethardwarenodes.map(async node => await fetchApi("kepler", "/nightnurse/streams/node", "GET", {
                "locationid": hasKepler[0].value,
                "nodeid": node.value
            })));
            setStreams(locationStreams);
        }else{
            const locstreams = await Promise.all(gethardwarenodes.map(async node => {
                const locconf = await fetchApi("mba", `/mba/organization/detailconfig/${node.objectid}`, "GET", {
                    "objectid": node.objectid
                });
                if(locconf && locconf.length > 0){
                    const locHasKepler = locconf.filter(param => param.name === "KeplerID");
                    return await fetchApi("kepler", "/nightnurse/streams/node", "GET", {
                        "locationid": locHasKepler[0].value, "nodeid": node.value
                    });
                }
                
            }));

            setStreams(locstreams);
        }

        setDevice(data[0]);
        setParameters(params);
        setLocations(location);
        setStatusses(status);
        setKeplerhardwarenodes(getkeplerhardwarenodes)
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <form type="POST" onSubmit={(event) => changeData(event)}>
                    <input className="btn btn-save" style={{ marginTop: '0px' }} type="submit" name="submit" value={t('save')} />
                    <h1><button className="goBackBtn" onClick={() => navigate('/manage/devices/object/' + device.devicetypeid)}><i class="fas fa-arrow-circle-left"></i></button> {t('configuration')}</h1>

                    <div className="alert alert-success alert-update" role="alert">
                        {t('updatedevice')}
                    </div>

                    <div class="alert alert-danger" role="alert">
                        {t('somethingwrong')}
                    </div>

                    <div className='err_msg'></div>
                    <div className="deviceDetails">
                        <table className="table">
                            <tr className="roweven">
                                <td className="td">{t('name')}</td>
                                <td className="td"><input type="text" name="name" id="name" defaultValue={device.devicename} className="form-control" /></td>
                            </tr>
                            <tr className="roweven">
                            <td className="td" style={{ verticalAlign: 'top' }}>{t('location')}</td>
                                <td className="td">
                                    <ObjectLocation type="device" objectid={device.locationid} />
                                </td>
                            </tr>
                        </table>
                    </div>

                    <h2>{t('detailconfiguration')}</h2>

                    <div className="deviceDetails">
                        <table className="table">
                            {parameters.map((params, i) => {
                                var uniquevalue = params.uniquevalue === 1 ? true : false;
                                
                                if (params.parametername === 'Trigger1') {
                                    return (
                                        <tr>
                                            <td className="td">{params.parametername}</td>
                                            <td className="td">
                                                <select key={`${Math.floor((Math.random() * 1000))}-min`} name="status" 
                                                // onChange={(event) => { updateDeviceConfField(device.deviceid, device.devicename, params.parameterid, event.target.value) }} 
                                                id="statusses" defaultValue={params.parametervalue} className="form-control">
                                                    <option value='0'>{t('select')}</option>
                                                    {statusses.map((status, i) => {
                                                        return (
                                                            <option value={status.statusid}>{status.name} ({status.statusid})</option>
                                                        )
                                                    })}
                                                </select>
                                                <input type="hidden" name={params.parametername} data-param={params.parameterid} id={params.parametername} defaultValue={params.parametervalue} />
                                            </td>
                                        </tr>
                                    )
                                }else if(params.parametername === 'KeplerGUID'){
                                    return (
                                        <tr>
                                            <td className="td">{params.parametername}</td>
                                            <td className="td">
                                                <select key={`${Math.floor((Math.random() * 1000))}-min`} name="status" 
                                                // onChange={(event) => { updateDeviceConfField(device.deviceid, device.devicename, params.parameterid, event.target.value) }} 
                                                id="statusses" defaultValue={params.parametervalue} className="form-control">
                                                    <option value='0'>{t('select')}</option>
                                                    {streams && streams.length > 0 ? streams[0].map((stream, i) => {
                                                        var selected = stream.uuid === params.parametervalue ? true : false;
                                                        return (
                                                            <option selected={selected} value={stream.uuid}>{stream.name}</option>
                                                        )
                                                    }) : ''}
                                                </select>
                                                <input type="hidden" name={params.parametername} data-param={params.parameterid} id={params.parametername} defaultValue={params.parametervalue} />
                                            </td>
                                        </tr>)
                                    
                                }else if(params.parametername === 'HardwareNodeID'){
                                    return (
                                    <tr>
                                        <td className="td">{params.parametername}</td>
                                        <td className="td">
                                            <select key={`${Math.floor((Math.random() * 1000))}-min`} name="status" 
                                            id="statusses" defaultValue={params.parametervalue} className="form-control">
                                                <option value='0'>{t('select')}</option>
                                                {keplerhardwarenodes.map((node, i) => {
                                                    return (
                                                        <option value={node.uuid}>{node.name}</option>
                                                    )
                                                })}
                                            </select>
                                            <input type="hidden" name={params.parametername} data-param={params.parameterid} id={params.parametername} defaultValue={params.parametervalue} />
                                        </td>
                                    </tr>)
                                } else {
                                    return (
                                        <tr>
                                            <td className="td">{params.parametername}</td>
                                            <td className="td">
                                                <input type="text" onChange={async (event) => { 
                                                    var checkunique = await checkConfigField(uniquevalue, params.parametername, event.target.value);
                                                    event.target.style.border = `solid 1px ${checkunique.unique == false ? 'red' : '#ced4da'}`;
                                                    event.target.parentElement.lastChild.style.display = checkunique.unique == false ? 'block' : 'none';
                                                }} name={params.parametername} data-param={params.parameterid} id={params.parametername} defaultValue={params.parametervalue} class="form-control" />
                                                <span style={{ display:'none', color: 'red' }} className='param_err'>Combinatie parameter en waarde wordt al gebruikt voor apparaat <span id={`duplicatedevice-${params.parameterid}`} className='duplicatedevice'></span></span>
                                            </td>
                                        </tr>
                                    )
                                }
                        })}
                        </table> 
                    </div>
                </form>
                
            </div>
        </React.Fragment>
    );
}

export default withRouter(DeviceDetails);
