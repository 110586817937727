import {fetchApi} from "../../../Functions.js";

const getAzureDetails = async () => {
  const authurl = await fetchApi('mba', '/mba/settings/get/azure', "get");
  return authurl;
}

export const msalConfig = async () => {
  const azure = await getAzureDetails();

  return{
    auth: {
      clientId: azure['clientid'],
      authority: azure['url'], 
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage", 
      storeAuthStateInCookie: false, 
    }
  }
}
  
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };