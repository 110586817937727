import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import AuthService from '../AuthService';
import { loginRequest } from "./authConfig";

export const SignInButton = () => {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    var Auth = new AuthService();

    const scopes = {
        account: accounts[0],
        scopes: ["user.Read"]
    }

    const handleLogin = (instance) => {
        instance.loginPopup(loginRequest).catch(e => {
            console.error(e);
        });
    }

    if(isAuthenticated){
        instance.acquireTokenSilent(scopes).then(async response => {
            const graph = await Auth.getGraphInfo(response.accessToken);
            localStorage.setItem("AD_office", graph.officeLocation);

            if(graph){
                Auth.ADLogin(graph.userPrincipalName, response.idToken);
            }
        })
    }

    return (
        <button variant="secondary" className="btn-azure ml-auto" onClick={() => handleLogin(instance)}>Login with Azure</button>
    );
}