import React, { Component, useEffect, useState } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import * as moment from 'moment';
import LocationAlert from "../../../components/LocationAlert.js";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchApi } from "../../../Functions.js";
import nl from 'date-fns/locale/nl';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Export from '../../../components/Export.js';
registerLocale('nl', nl);
moment.locale('nl');

const AlarmPerformance = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [totals, setTotals] = useState([]);
    const [performance, setPerformance] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    var SLT = 0;
    var reportdatetill = '';
    var averagetime, totaltime, servicelevel = 0;
    var currentdate = new Date(props.router.params.reportdate);

    const getServiceLevelData = async () => {
        const servicelevelData = await fetchApi("mba", "/mba/settings/serviceleveltime", "GET");
        return servicelevelData;
    }

    const getAlarmPerformances = async (date, date2, init, servicelevel) => {
        const total = await fetchApi("mba", "/mba/alarmperformance/totals", "GET", {
            "reportdatefrom": date,
            "reportdatetill": date2,
            "serviceleveltime": servicelevel
        }); 

        const data = await fetchApi("mba", "/mba/alarmperformance", "GET", {
            "reportdatefrom": date,
            "reportdatetill": date2,
            "serviceleveltime": servicelevel
        }); 

        setTotals(total[0]);

        data.forEach((performance) => {
            SLT = SLT + performance.Servicelevel;
        })

        var $data = [];

        for (var i = 0; i <= 23; i++) {
            $data.push({
                Time: i + ':00',
                Alarms: "0",
                Totaltime: "0",
                Averagetime: "0",
                Averagespeed: "0",
                Servicelevel: "0"
            });

            $data.push({
                Time: i + ':30',
                Alarms: "0",
                Totaltime: "0",
                Averagetime: "0",
                Averagespeed: "0",
                Servicelevel: "0"
            });

            for (var j = 0; j < data.length; j++) {
                if (data[j].hour === i) {
                    if (data[j].minutes === 0) {
                        $data.forEach((alarm) => {
                            if (alarm.Time === i + ":00") {
                                alarm.Time = i + ':00';
                                alarm.Alarms = data[j].nrofalarms;
                                alarm.Totaltime = data[j].totaltime;
                                alarm.Averagetime = data[j].averagetime;
                                alarm.Averagespeed = data[j].averagespeed;
                                alarm.Servicelevel = data[j].servicelevel;
                            }
                        })
                    }

                    if (data[j].minutes === 30) {
                        $data.forEach((alarm) => {
                            if (alarm.Time === i + ":30") {
                                alarm.Time = i + ':30';
                                alarm.Alarms = data[j].nrofalarms;
                                alarm.Totaltime = data[j].totaltime;
                                alarm.Averagetime = data[j].averagetime;
                                alarm.Averagespeed = data[j].averagespeed;
                                alarm.Servicelevel = data[j].servicelevel;
                            }
                        })
                    }
                }
            }
        }
        setPerformance($data);
    }

    const changeDate = async (date, date2, init) => {
        var url = window.location.href.split("/");

        if (!date2 || date2 && date2 === '') {
            date2 = date;
        }

        const servicelevelData = await getServiceLevelData();
        navigate(date2 && date2 !== '' ? '/' + url[3] + '/' + url[4] + '/' + date + '/' + date2 : '/' + url[3] + '/' + url[4] + '/' + date);
        getAlarmPerformances(date, date2, false, servicelevelData[0].value);
    }

    averagetime = totals.averagetime ? totals.averagetime : '00:00:00';
    totaltime = totals.totaltime ? totals.totaltime : '00:00:00';
    servicelevel = totals.servicelevel ? totals.servicelevel : '0';

    const getData = async () => {
        reportdatetill = props.router.params.reportdate2 ? props.router.params.reportdate2 : props.router.params.reportdate;
        const servicelevelData = await getServiceLevelData();
        getAlarmPerformances(props.router.params.reportdate, reportdatetill, true, servicelevelData[0].value);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('alarmperformance')}</h1>

                <div className="filters">
                    {t('date')}:
                    <DatePicker locale="nl" dateFormat="yyyy-MM-dd"
                        selected={startDate ? new Date(startDate) : currentdate}
                        onChange={(data) => setStartDate(moment(data).format('YYYY-MM-DD'))}
                    />

                    <DatePicker locale="nl" dateFormat="yyyy-MM-dd"
                        selected={endDate ? new Date(endDate) : ''}
                        onChange={(data) => setEndDate(moment(data).format('YYYY-MM-DD'))}
                    />

                    <div className="search-btn" onClick={() => changeDate(startDate, endDate)}><i className="fa fa-search" style={{ color: '#ffffff' }}></i></div>
                    <Export />
                </div>

                <div className="performanceTotal">
                    <div className="row">
                        <div className="col-md-3 performanceBlock">
                            <i className="far fa-bell"></i>
                            <div className="block_title">{t('nrofalarms')}</div>
                            <div className="block_value">{totals.nrofalarms}</div>
                        </div>
                        <div className="col-md-3 performanceBlock">
                            <i className="far fa-clock"></i>
                            <div className="block_title">{t('averageduration')}</div>
                            <div className="block_value">{averagetime}</div>
                        </div>
                        <div className="col-md-3 performanceBlock">
                            <i className="far fa-clock"></i>
                            <div className="block_title">{t('totalduration')}</div>
                            <div className="block_value">{totaltime}</div>
                        </div>
                        <div className="col-md-3 performanceBlock">
                            <i className="fas fa-percentage"></i>
                            <div className="block_title">{t('servicelevel')}</div>
                            <div className="block_value">{servicelevel}</div>
                        </div>
                    </div>
                </div>

                <div className="DataTable_Container">
                <div className="table-responsive">
                    <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr>
                                <th>{t('time')}</th>
                                <th>{t('nrofalarms')}</th>
                                <th>{t('averageanswering')}</th>
                                <th>{t('averageduration')}</th>
                                <th>{t('totalduration')}</th>
                                <th>{t('servicelevel')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {performance.map((Log, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{Log.Time}</td>
                                        <td>{Log.Alarms}</td>
                                        <td>{Log.Averagespeed}</td>
                                        <td>{Log.Averagetime}</td>
                                        <td>{Log.Totaltime}</td>
                                        <td>{Log.Servicelevel}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(AlarmPerformance);
