import React, { Component, useEffect, useState } from 'react';
import withAuth from './Login/withAuth.js';
import * as moment from 'moment';
import { NavLink, useNavigate } from "react-router-dom";
import LocationSelector from './LocationSelector.js';
import { useTranslation } from 'react-i18next';
import {toggleSidebar} from '../Functions.js';
moment.locale('nl');

const SidebarManage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [modules, setModules] = useState([]);
    const [currentRole, setCurrentRole] = useState([]);

    const getAllData = async () => {
        const functions = await import("../Functions.js");

        const role = await functions.fetchApi("mba", `/mba/modules/roles/get/${localStorage.getItem('Role')}`, "GET", {
            "roleid": localStorage.getItem('Role')
        });
        setCurrentRole(role[0]);

        var categoriesResponse = await functions.fetchApi("mba", "/mba/modules/categories/get", "GET");
        var modulesResponse = await functions.fetchApi("mba", "/mba/modules/get", "GET");

        categoriesResponse = categoriesResponse.filter(cat => cat.id === 3 || cat.id === 4);

        const categoriesArray = categoriesResponse.map(category => {
            let catModules = 0;

            modulesResponse.forEach(module => {
                if (module.categorie === category.id && module.active === 1 && role[0] && role[0].modules.split(",").includes(module.id.toString())) {
                    catModules += 1;
                }
            });

            return { id: category.id, name: category.name, slug: category.slug, modules: catModules };
        });

        categoriesArray.sort((a, b) => a.id - b.id);
        setCategories(categoriesArray);
        setModules(modulesResponse);
    };

    const getRole = async () => {
        getAllData();
    }

    useEffect(() => {
        getRole();
    }, []);

    return (
        <div className="sidebar">
            <img alt="logo" className='logo' src="/images/ch-logo.jpg" />
            <div className='close-sidebar' onClick={() => toggleSidebar('close')}><i className="fas fa-close"></i></div>
            <ul className="sidebar_menu">
                <button onClick={() => { window.location.href = `/` }} className="btn btn-backtodash"><i className="fa fa-arrow-left"></i> {t('backtodashboard')}</button>
                {!localStorage.getItem('AD') && (<LocationSelector />)}
                {categories.map((category, i) => {
                    const catDisplay = category.modules > 0 ? 'block' : 'none';

                    return (
                        <li className="menu-item" style={{ display: catDisplay }} key={category.id}>
                            <div className="menu-item-label">
                                <div className="menu-item-label-name">{t(`${category.slug}`)}</div>
                            </div>
                            <div className="menu-collapse">
                                <ul>
                                    {modules.map((module, j) => {
                                        var moduleurl = module.url.split("/");
                                        
                                        var newUrl = '';

                                        if(moduleurl[1] === 'reports' && moduleurl[2] === 'activity'){
                                            newUrl = module.url.toString().replace(":date", moment().subtract(1, 'days').format('YYYY-MM-DD'));
                                        }else{
                                            newUrl = module.url.toString().replace(":date", moment().format('YYYY-MM-DD'));
                                        }
                                        
                                        module.url = newUrl;

                                        if (module.categorie === category.id && module.active === 1 && currentRole && currentRole.modules.split(",").includes(module.id.toString())) {
                                            return (
                                                <NavLink to={module.url} exact="true" key={module.id}>
                                                    <li className="submenu-item" onClick={() => toggleSidebar('close')}>
                                                        <i className={`icon ${module.icon}`}></i> {t(`${module.slug}`)}
                                                    </li>
                                                </NavLink>
                                            );
                                        }

                                        return null;
                                    })}
                                </ul>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default withAuth(SidebarManage);