import React, { useEffect, useState } from 'react';
import Menu from '../../components/MbaMenu.js';
import withRouter from '../../components/withRouter';
import { crudAlertControl, checkMBAUserPermission } from './Functions.js';
import { fetchApi } from '../../Functions';

const TemplateDetails = (props) => {
    const [template, setTemplate] = useState([]);
    const [types, setTypes] = useState([]);
    const [connections, setConnections] = useState([]);
    const [messages, setMessages] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [incmsg, setIncMsg] = useState([]);
    const [timetables, setTimetables] = useState([]);
    const [tempww, setTempww] = useState('');

    const getData = async () => {
        const [currentTemplate, types, conndata, msg, incmsgs, timetablesdata, clusterdata, gettemplates, tmpww] = await Promise.all([
            await fetchApi("mba", `/mba/admin/template/${props.router.params.id}`, "GET", { "templateid": props.router.params.id }),
            await fetchApi("mba", "/mba/devicetype/get", "GET"),
            await fetchApi("mba", "/mba/admin/responserule/connections/", "GET"),
            await fetchApi("mba", "/mba/organization/outgoingmsgs/", "GET"),
            await fetchApi("mba", "/mba/organization/incomingmsgs/", "GET"),
            await fetchApi("mba", "/mba/admin/timetable/get", "GET"),
            await fetchApi("mba", "/mba/admin/responserule/clusters", "GET"),
            await fetchApi("mba", "/mba/admin/templates/get", "GET"),
            await fetchApi("blyott", "/password/tmpww", "GET"),
        ])
        setTemplate(currentTemplate[0]);
        setTypes(types);
        setConnections(conndata);
        setMessages(msg);
        setIncMsg(incmsgs);
        setTimetables(timetablesdata);
        setClusters(clusterdata);
        setTempww(tmpww);

        var currentTmpWW = await fetchApi("blyott", "/password/tmpww", "GET");

        if(!localStorage.getItem('temp_ww') || localStorage.getItem('temp_ww') && localStorage.getItem('temp_ww') !== currentTmpWW){
            window.location.href = '/mba-admin/responserules/templates';
        }
    }

    const changeTemplate = async (event) => {
        event.preventDefault();
        const template = await fetchApi("mba", `/mba/admin/template/${props.router.params.id}/update`, "GET", {
            "templateid": props.router.params.id, "devicetypeid": event.target.devicetype.value, "incomingconnection": event.target.incoming.value, "incomingmessage": event.target.incomingmsg.value, "outgoingconnection": event.target.outgoing.value, "outgoingmessage": event.target.outgoingmsg.value, "timetableid": event.target.timetable.value, "clusterid": event.target.cluster.value, 'priority': event.target.priority.value, "active": event.target.active.checked, "enabled": event.target.enabled.checked
        })
        await getData();
        crudAlertControl('update', template);
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();
    }, [])

    return(
        <React.Fragment>
            <Menu />

            <form onSubmit={(event) => changeTemplate(event)}>
            <input style={{marginTop: '0px', marginBottom: '20px'}} type="submit" name="submit" value="Opslaan" className='btn btn-save' />
            <h2 style={{ marginBottom: '30px' }}>Template Details</h2>

            <div className="alert alert-success alert-update" role="alert">
                Template gewijzigd.
            </div>

            <div class="alert alert-danger" role="alert">
                Er is iets niet goed gegaan. Probeer het later nog eens.
            </div>

            <div className="DataTable_Container" style={{ background: '#ffffff', padding: '30px' }}>
            <table width="100%">
                <tbody>
                <tr>
                    <td>DeviceType</td>
                    <td>
                        <select name="devicetype" id="devicetype" className='form-control' key="template_devicetype" defaultValue={template.devicetypeid}>
                            <option key="0" value="0">Selecteer devicetype</option>
                            {types.map((type, i) => {
                                var selected = type.devicetypeid === template.devicetypeid ? "true" : '';
                                return(<option key={i} selected={selected} value={type.devicetypeid}>{type.devicetypename}</option>)
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Incoming connection</td>
                    <td>
                        <select name="incoming" id="incoming" className='form-control' key="template_incCon" defaultValue={template.incoming}>
                            <option key="0" value="0">Selecteer connection</option>
                            {connections.map((connection, i) => {
                                var selected = connection.id === template.incoming ? "true" : '';
                                return(<option key={i} selected={selected} value={connection.id}>{connection.name}</option>)
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Incoming messages</td>
                    <td>
                        <select name="incomingmsg" id="incomingmsg" className='form-control' key="template_incmsg" defaultValue={template.incomingmsg}>
                            <option value="0">Selecteer message</option>
                            {incmsg.map((msg, i) => {
                                var selected = msg.id === template.incomingmsg ? "true" : '';
                                return <option key={i} selected={selected} value={msg.id}>{msg.name}</option>
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Outgoing connection</td>
                    <td>
                        <select name="outgoing" id="outgoing" className='form-control' key="template_outCon" defaultValue={template.outgoing}>
                            <option key="0" value="0">Selecteer connection</option>
                            {connections.map((connection, i) => {
                                var selected = connection.id === template.outgoing ? "true" : '';
                                return(<option key={i} selected={selected} value={connection.id}>{connection.name}</option>)
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Outgoing messages</td>
                    <td>
                        <select name="outgoingmsg" id="outgoingmsg" className='form-control' key="template_outMsg" defaultValue={template.outgoingmsg}>
                            <option value="0">Selecteer message</option>
                            {messages.map((msg, i) => {
                                var selected = msg.id === template.outgoingmsg ? "true" : '';
                                return <option key={i} selected={selected} value={msg.id}>{msg.name}</option>
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Enabled</td>
                    <td>
                        <label className='switch'>
                            <input defaultChecked={template.enabled === 1 ? "true" : ""} key={`${Math.floor((Math.random() * 1000))}-min`} name="enabled" id="enabled" type='checkbox' />
                            <span className='slider round'></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>Active</td>
                    <td>
                        <label className='switch'>
                            <input defaultChecked={template.active === 1 ? "true" : ""} key={`${Math.floor((Math.random() * 1000))}-min`} name="active" id="active" type='checkbox' />
                            <span className='slider round'></span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <td>Timetable</td>
                    <td>
                        <select name="timetable" id="timetable" className='form-control' key="template_timetable" defaultValue={template.timetableid}>
                            <option value="0">Selecteer timetable</option>
                            {timetables.map((timetable, i) => {
                                var selected = timetable.id === template.timetableid ? "true" : '';
                                return <option key={i} selected={selected} value={timetable.id}>{timetable.name}</option>
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Cluster</td>
                    <td>
                        <select name="cluster" id="cluster" className='form-control' key="template_cluster" defaultValue={template.clusterid}>
                            <option value="0">Selecteer cluster</option>
                            {clusters.map((cluster, i) => {
                                var selected = cluster.id === template.clusterid ? "true" : '';
                                return <option key={i} selected={selected} value={cluster.id}>{cluster.name}</option>
                            })}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Priority</td>
                    <td>
                        <input type="text" id="priority" name="priority" className='form-control' value={template.outgoingpriority}/>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
            </form>
        </React.Fragment>
    )
}

export default withRouter(TemplateDetails);