import React, { useEffect, useState } from 'react';
import '../assets/style.css';
import AuthService from '../components/Login/AuthService.js';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { fetchApi } from "../Functions.js";
import { MsalProvider, useAccount, useMsal } from '@azure/msal-react';
import { SignInButton } from '../components/Login/ADAuth/signInButton.js';
import { msalConfig } from "../components/Login/ADAuth/authConfig.js";
import { PublicClientApplication } from '@azure/msal-browser';

const $ = require('jquery');

const Login = (props) => {
    // const msalInstance = new PublicClientApplication(msalConfig);
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [verify, setVerify] = useState(0);
    const [status, setStatus] = useState('');
    const [getAzureBtn, setAzureBtn] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedin, setLoggedin] = useState('');
    const [msalInstance, setMsalInstance] = useState(null); 
    
    var Auth = new AuthService();

    const fetchMsalConfig = async () => {
        const config = await msalConfig();  // Await the msalConfig async function to get the object
        setMsalInstance(new PublicClientApplication(config));
    };

    const getGraphInfo = (accessToken, idToken) => {
        return fetch("https://graph.microsoft.com/v1.0/me", {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + accessToken,
              'Content-Type': 'application/json',
            },
          })
          .then(response => response.json())
          .then(data => {
            console.log(data.givenName + " " + data.surname, idToken);
                Auth.ADLogin(data.givenName + " " + data.surname, idToken);
          });
    }

    const QRCode = () => {
        fetchApi("mba", "/mba/settings/login/qr", "GET").then(data => {
            if (data[0] && data[0].value === 1) {
                $('.login').hide();
                $('.verification').show();
            } else {
                if(loggedin){
                    navigate("/");    
                }
            }
        })

        return null;
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        Auth.login(username, password).then((res) => {
            if (res && res.user.id) {
                setLoggedin(true);
                window.location.reload();
            } else {
                if (res !== false && res.user.id) {
                    QRCode();
                } else {
                    $('.err_msg').html(t('invalidcredentials'));
                    $('.err_msg').fadeIn();
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };


    const handleChange = (e) => {
        if(e.target.name === 'username'){
            setUsername(e.target.value);
        }else{
            setPassword(e.target.value);
        }
    }

    const getData = async () => {
        const [getapistatus, getazurelogin] = await Promise.all([
            fetchApi("mba", "/settings/status", "GET"),
            fetchApi("mba", "/mba/settings/azure", "GET")
        ])

        await fetchMsalConfig();

        setStatus(getapistatus);
        setAzureBtn(getazurelogin[0]);

        const handleEvent = (event) => {
            const { accessToken, idToken } = event.payload;

            if (accessToken && idToken) {
                sessionStorage.setItem('AccessToken', accessToken);
                getGraphInfo(accessToken);
            }
        };

        if (msalInstance) {
            msalInstance.addEventCallback(handleEvent);
        }
    }

    const text = verify;

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="center">
            <img className="login_logo" alt="logo" src="/images/ch-logo.jpg" />
            <div className="login">
                <form onSubmit={(e) => handleFormSubmit(e)}>
                    <input
                        className="form-control"
                        placeholder={t('username')}
                        name="username"
                        type="text"
                        onChange={handleChange}
                    />
                    <input
                        className="form-control"
                        placeholder={t('password')}
                        name="password"
                        type="password"
                        onChange={handleChange}
                    />
                    <input
                        className="form-submit"
                        value={t('login')}
                        type="submit"
                    />
                    <div className="err_msg"></div>
                </form>

                {getAzureBtn && getAzureBtn['value'] === "1" && (
                    <MsalProvider instance={msalInstance}>
                        <SignInButton />
                    </MsalProvider>
                )}
                
                <div className='api-status' style={{ background: status === "OK" ? 'green' : '#FF0000' }}></div>
            </div>

            <div className="verification">
                <QRCode text={`Toegangscode CHC: ${text}`} size="150" />
                <h2 style={{ textAlign: 'center', marginTop: '20px' }} >{t('qrcode')}</h2>
                <p>{t('qrcodetext')}</p>

                <div style={{ marginTop: '20px', fontSize: '14px' }}>
                    Code <input style={{ border: 'solid 1px #dddddd', height: '30px', padding: '5px' }} type="text" name="verifyCode" id="verifyCode" />
                    <button className="verify">{t('confirm')}</button>
                    <div className="msg"></div>
                </div>
            </div>
        </div>
    ); 
}

export default Login;