import React, { Component, useState, useEffect } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import * as moment from 'moment';
import LocationAlert from "../../../components/LocationAlert.js";
import { useTranslation } from 'react-i18next';
moment.locale('nl');
const $ = require('jquery');

const ReportHistoryAlarmTechDetails = (props) => {
    const [alarm, setAlarm] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        const functions = await import("../../../Functions.js");
        const data = await functions.fetchApi("mba", `/mba/report/historyalarmtech/${props.router.params.id}`, "GET", {
            "alarmid": props.router.params.id
        });
        setAlarm(data[0]);
    }

    useEffect(() => {
      getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <div className="row">
                    <div className="col-md-12">
                        <h2><button className="goBackBtn" onClick={() => window.history.back()}><i cclassNamelass="fas fa-arrow-circle-left"></i></button> {alarm.object} ({alarm.objectid})</h2>
                        <div className="alarmDetails">
                            <table>
                                <tr>
                                    <td><strong>{t('from')}</strong></td>
                                    <td>{alarm.object} ({alarm.objectid})</td>
                                </tr>
                                <tr>
                                    <td><strong>{t('type')}</strong></td>
                                    <td>{t(`${alarm.slug}`)}</td>
                                </tr>
                                <tr>
                                    <td><strong>{t('createdon')}</strong></td>
                                    <td>{alarm.uptriggered ? moment(alarm.uptriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                </tr>
                                <tr>
                                    <td><strong>{t('confirmedon')}</strong></td>
                                    <td>{alarm.downtriggered ? moment(alarm.downtriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                </tr>
                                <tr>
                                    <td><strong>{t('solvedon')}</strong></td>
                                    <td>{alarm.finishtriggered ? moment(alarm.finishtriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                </tr>
                                <tr>
                                    <td><strong>{t('canceledon')}</strong></td>
                                    <td>{alarm.canceltriggered ? moment(alarm.canceltriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(ReportHistoryAlarmTechDetails);