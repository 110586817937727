import React, { Component, useEffect, useState } from 'react';
import withRouter from '../../../components/withRouter';
import '../../../assets/style.css';
import * as moment from 'moment';
import { calcAlarmDuration } from "../../../Functions";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import LocationAlert from "../../../components/LocationAlert.js";
import { initDataTable, initDataTable2, initDataTable3 } from '../../../Functions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
registerLocale('nl', nl);
moment.locale('nl');
const $ = require('jquery');
$.DataTable = require('datatables.net')

const EmployeeDash = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [employee, setEmployee] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [nrOfAlarms, setNrOfAlarms] = useState(0);
    const [logins, setLogins] = useState([]);
    const [nrOfLogins, setNrOfLogins] = useState(0);
    const [logging, setLogging] = useState([]);
    const [nrOfLogs, setNrOfLogs] = useState(0);
    var currentDate = new Date();
    var employeeid = props.router.params.id;

    const goToDate = (event) => {
        navigate("/reports/employeedash/" + props.router.params.id + "/" + event.target.value);
    }

    const datesArray = Array.from({ length: 13 }, (_, i) => {
        const date = moment(currentDate).subtract(i, 'months');
        return {
            month: date.format('MM'),
            year: date.format('YYYY'),
            datename: date.format('MMMM YYYY')
        };
    });

    const getData = async () => {
        const functions = await import("../../../Functions.js");
        const dashlog = await functions.fetchApi("mba", "/mba/report/employeedash/logging", "GET", {
            "reportdate": props.router.params.reportdate,
            "employeeid": employeeid
        })
        const info = await functions.fetchApi("mba", "/mba/report/employeedash/employee", "GET", {
            "employeeid": employeeid
        })
        const dashalarms = await functions.fetchApi("mba", "/mba/report/employeedash/alarms", "GET", {
            "reportdate": props.router.params.reportdate,
            "employeeid": employeeid
        })

        const dashlogins = await functions.fetchApi("mba", "/mba/report/employeedash/logins", "GET", {
            "reportdate": props.router.params.reportdate,
            "employeeid": employeeid
        })

        currentDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1);
        var nrOfLogs = 0;

        setLogging(dashlog);
        setNrOfLogs(nrOfLogs);
        initDataTable('#DataTable.datatable3');

        await Promise.all(info.map((employee, index, receive) => {
            return functions.fetchApi("mba", `/phonebook/get/${props.router.params.id}/teams`, "GET", {
                "contactid": props.router.params.id
            }).then(teams => {
                employee['teams'] = teams;
            })
        }));

        await Promise.all(info.map((employee, index, receive) => {
            return functions.fetchApi("mba", `/mba/phonebook/device/${employee.deviceid}`, "GET", {
                "deviceid": employee.deviceid
            }).then(device => {
                employee['devicedata'] = device[0];
            })
        }));

        setEmployee(info);

        var nrOfAlarms = 0;

        await Promise.all(dashalarms.map((alarm, index, receive) => {
            nrOfAlarms += 1;

            return functions.fetchApi("mba", `/mba/report/historyalarm/${alarm.objectalarmid}/teams`, "GET", {
                "alarmid": alarm.objectalarmid
            }).then(teams => {
                alarm['teams'] = teams;
            })
        }));

        setAlarms(dashalarms);
        setNrOfAlarms(nrOfAlarms);
        initDataTable('#DataTable.datatable2');

        var nrOfLogins = 0;

        await Promise.all(dashlogins.map((employee, index, receive) => {
            nrOfLogins += 1;

            return functions.fetchApi("mba", `/mba/report/employee/${employee.objectid}/teams`, "GET", {
                "employeeid": employee.objectid,
                "logindate": moment(employee.logintime).format('YYYY-MM-DD HH:mm:ss'),
                "logoutdate": moment(employee.logouttime).format('YYYY-MM-DD HH:mm:ss'),
                "reportdate": currentDate
            }).then(teams => {
                employee['teams'] = teams;
            })
        }));

        setLogins(dashlogins);
        setNrOfLogins(nrOfLogins);
        initDataTable();

        currentDate = new Date();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{employee && employee[0] ? employee[0].employee : t('employeereports')}</h1>
                <div className="filters">
                    {t('date')}:
                    <select className='dateSelect' onChange={(event) => goToDate(event)}>
                        {datesArray.map(date => {
                            var selected = '';

                            if (date.year + '-' + date.month == props.router.params.reportdate) {
                                selected = "selected";
                            } else {
                                selected = "";
                            }
                            return (<option selected={selected} value={date.year + '-' + date.month}>{date.datename}</option>)
                        })}
                    </select>

                    <div id="buttons" style={{ float: 'right' }}></div>
                </div>

                <div className='row'>
                    <div className="col-md-12">
                        <h2>{t('loginlogouttimes')}</h2>
                        <div className="DataTable_Container">
                        <div className="table-responsive">
                            <table id="DataTable" className="showHead table responsive" width="100%">
                                <thead>
                                    <tr className="rowtitle">
                                        <th>{t('loggedin')}</th>
                                        <th>{t('loggedout')}</th>
                                        <th>{t('teams')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logins.map((login) => {
                                        return (
                                            <tr>
                                                <td>{login.logintime ? moment(login.logintime).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                <td>{login.logouttime ? moment(login.logouttime).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                <td>
                                                    {login.teams.map((team, i) => {
                                                        return (<div className='contact-team'>{team.teamname}</div>)
                                                    })}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <h2>{t('alarms')}</h2>
                        <div className="DataTable_Container">
                        <div className="table-responsive">
                            <table id="DataTable" className="datatable2 showHead table responsive" width="100%">
                                <thead>
                                    <tr className="rowtitle">
                                        <th>{t('object')}</th>
                                        <th>{t('type')}</th>
                                        <th>{t('team')}</th>
                                        <th>{t('createdon')}</th>
                                        <th>{t('confirmedon')}</th>
                                        <th>{t('solvedon')}</th>
                                        <th>{t('canceledon')}</th>
                                        <th>{t('duration')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {alarms.map((alarm) => {
                                        console.log(alarm);
                                        return (
                                            <tr onClick={() => { navigate(`/reports/historyalarms/alarm/${alarm.objectalarmid}`) }}>
                                                <td>{alarm.object}</td>
                                                <td>{alarm.status}</td>
                                                <td>{alarm.teams.map((team, i) => {
                                                    return (<div className='contact-team'>{team.name}</div>)
                                                })}</td>
                                                <td>{alarm.uptriggered ? moment(alarm.uptriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                <td>{alarm.downtriggered ? moment(alarm.downtriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                <td>{alarm.finishtriggered ? moment(alarm.finishtriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                <td>{alarm.canceltriggered ? moment(alarm.canceltriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                                <td>{calcAlarmDuration(alarm)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <h2>{t('alarmlogging')}</h2>
                        <div className="DataTable_Container">
                        <div className="table-responsive">
                            <table id="DataTable" className="datatable3 showHead table responsive" width="100%">
                                <thead>
                                    <tr className="rowtitle">
                                        <th>{t('alarm')}</th>
                                        <th>{t('object')}</th>
                                        <th>{t('date')}/{t('time')}</th>
                                        <th>{t('active')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logging.map((log, i) => {
                                        var dotColor = log.action === 0 ? '#FF0000' : '#00FF00';

                                        return (
                                            <tr>
                                                <td>{log.alarm}</td>
                                                <td>{log.object}</td>
                                                <td>{moment(log.dateaction).format('DD-MM-YYYY HH:mm:ss')}</td>
                                                <td><div style={{ width: '10px', height: '10px', background: dotColor, borderRadius: '100%' }}></div></td>
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(EmployeeDash);