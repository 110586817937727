import React, { Component, useEffect, useState } from 'react';
import '../../../assets/style.css';
import withAuth from '../../../components/Login/withAuth';
import * as moment from 'moment';
import 'react-tabs/style/react-tabs.css';
import DashboardStandard from '../../../components/Dashboard/DashboardStandard';
import DashboardCustom from '../../../components/Dashboard/DashboardCustom';
const $ = require('jquery');
moment.locale('nl');

const Dashboard = (props) => {
    const [tabs, setTabs] = useState([]);

    const showDashboard = () => {
        if (tabs && tabs.length === 0) {
            return (<DashboardStandard filter={props && props.history && props.history.location && props.history.location.hash ? props.history.location.hash.split("#")[1] : ''} />)
        } else {
            return (<DashboardCustom filter={props && props.history && props.history.location && props.history.location.hash ? props.history.location.hash.split("#")[1] : ''} />);
        }
    }

    const getData = async () => {
        const functions = await import("../../../Functions");
        const data = await functions.fetchApi("mba", "/mba/cards/tabs", "GET");
        setTabs(data);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            {showDashboard()}
        </React.Fragment>
    )
}

export default withAuth(Dashboard);