import React, { useEffect, useState } from 'react';
import '../../assets/style.css';
import withAuth from '../../components/Login/withAuth';
import { confirmDelete, crudAlertControl, initDataTable, fetchApi } from '../../Functions';
import ModalDelete from '../../components/ModalDelete';
import { useTranslation } from 'react-i18next';
const $ = require('jquery');

const RFID = () => {
    const { t } = useTranslation();
    const [rfid, setRfid] = useState([]);
    const [clients, setClients] = useState([]);

    const deviceToClient = async (tagid, tag, clientid, clientname, weight) => {
      if(clientid && clientid > 0 && weight && weight > 0){
        const data = await fetchApi("mba", "/mba/device/insert/", "GET", {
          "name": tag, "devicetypeid": 14, "locationid": clientid,
        });

        if(data === "OK" || data.length > 0){
          const [addTag, addWeight] = await Promise.all([
            fetchApi("mba", `/mba/device/param/insert/${data[0].deviceid}`, "GET", {
              'deviceid': data[0].deviceid, 'name': data[0].devicename, 'parameterid': 62, 'value': tag,
            }),
            fetchApi("mba", `/mba/device/param/insert/${data[0].deviceid}`, "GET", {
              'deviceid': data[0].deviceid, 'name': data[0].devicename, 'parameterid': 63, 'value': tag,
            }),
          ]);

            if(addTag === "OK" && addWeight === "OK"){
              await fetchApi("mba", `/mba/rfid/${tagid}/delete`, "GET", {
                "tagid": tagid, "tagname": tag
              });

              $('.rfidclients').val(0);
        
              crudAlertControl('add', data);
              await getData();
            }
        }
      }else{
        crudAlertControl('not selected', []);
      }
    }

    const deleteList = async () => {
        const data = await fetchApi("mba", "/mba/rfid/deleteall", "GET");
        crudAlertControl('delete', data);
        getData();
        initDataTable();
    }

    const getData = async () => {
        const [clients, data] = await Promise.all([
          fetchApi("mba", "/mba/client/get", "GET", {
            "locationid": localStorage.getItem('currentLocation'),
          }),
          fetchApi("mba", "/mba/rfid/get", "GET")
        ])

        setClients(clients);
        setRfid(data);
    }

    useEffect(() => {
        getData();
        initDataTable();
    }, [])

    return (
        <React.Fragment>
          <div className="btn btn-truncate" onClick={() => confirmDelete(t('delitem'), function() { deleteList() })}>{t('emptylist')}</div>
          <h2>{t('rfid')}</h2>
    
          <div className="alert alert-success alert-add" role="alert">
            RFID apparaat toegewezen
          </div>

          <div className="alert alert-success alert-delete" role="alert">
            RFID lijst geleegd
          </div>
    
          <div class="alert alert-danger" role="alert">
            Er is iets niet goed gegaan. Probeer het later nog eens.
          </div>

          <div class="alert alert-danger alert-not-selected" role="alert">
            Tag en/of gewicht is niet toegewezen aan een client.
          </div>
    
          <div className="DataTable_Container">
          <div className="table-responsive">
            <table id="DataTable" class="showHead table responsive nowrap" width="100%">
              <thead>
                <tr class="rowtitle">
                  <th>{t('tag')}</th>
                  <th>{t('client')}</th>
                  <th>{t('weight')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rfid.map((device, i) => {
                  return (
                    <tr key={i}>
                      <td>{device.rfidtag}</td>
                      <td>
                        <select className="rfidclients" name={`clients_${device.rfidtag}`} id={`clients_${device.rfidtag}`}>
                            <option value="0">{t('select')}</option>
                            {clients.map(client => {
                                return(<option value={client.clientid}>{client.clientname}</option>)
                            })}
                        </select>
                      </td>
                      <td>
                        <select className="rfidweight" name={`weight_${device.rfidtag}`} id={`weight_${device.rfidtag}`}>
                            <option value="0">{t('select')}</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="60">60</option>
                            <option value="70">70</option>
                            <option value="80">80</option>
                            <option value="90">90</option>
                            <option value="100">100</option>
                        </select>
                      </td>

                      <td><button className="btn btn-save" onClick={() => { deviceToClient(device.tagid, device.rfidtag, $(`#clients_${device.rfidtag}`).val(), $( `#clients_${device.rfidtag} option:selected` ).text(), $(`#weight_${device.rfidtag}`).val())}} style={{ marginTop: '0px' }}>{t('assign')}</button></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            </div>
          </div>

          <ModalDelete />
        </React.Fragment>
      );
}

export default withAuth(RFID)