import React, { Component, useEffect, useState } from 'react';
import '../../../assets/style.css';
import withRouter from '../../../components/withRouter';
import LocationAlert from "../../../components/LocationAlert.js";
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import Export from '../../../components/Export.js';
moment.locale('nl');

const Heartbeats = (props) => {
  const [heartbeats, setHeartbeats] = useState([]);
  const { t } = useTranslation();

  const createMap = (devicename, user, lat, lng) => {
    const overlay = document.querySelector('.heartbeatmap-overlay');
    const titleElement = document.querySelector('.heartbeatmap .title');
    const mapElement = document.querySelector('.heartbeatmap .map');

    overlay.style.display = 'block';
    titleElement.textContent = `${devicename} - ${user}`;
    mapElement.innerHTML = `<iframe width="500" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src='https://maps.google.com/maps?width=400&amp;height=400&amp;hl=en&amp;q=${lat},%20${lng}&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'></iframe>`;
    document.querySelector('.heartbeatmap').style.display = 'block';
  };

  const closeMap = () => {
    const overlay = document.querySelector('.heartbeatmap-overlay');
    const mapElement = document.querySelector('.heartbeatmap');

    overlay.style.display = 'none';
    mapElement.style.display = 'none';
  };

  const getData = async () => {
    const functions = await import("../../../Functions.js");
    const data = await functions.fetchApi("mba", "/mba/report/heartbeats", "GET");
    setHeartbeats(data);
    functions.initDataTable();
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <React.Fragment>
      <LocationAlert />

      <div className='loc_content'>
      <h1>{t('heartbeatdevices')}</h1>

        <div className="heartbeatmap-overlay">
          <div className="heartbeatmap">
            <i onClick={() => closeMap()} className="closeMap fas fa-times-circle"></i>
            <h3 className="title"> - </h3>
            <div className="map"></div>
          </div>
        </div>

        <div className="filters">
        <Export />
        </div>

        <div className="DataTable_Container">
        <div className="table-responsive">
          <table id="DataTable" className="showHead table responsive nowrap" width="100%">
            <thead>
              <tr className="rowtitle">
                <th>{t('device')}</th>
                <th>{t('lastheartbeat')}</th>
                <th>{t('currentuser')}</th>
                <th>{t('latitude')}</th>
                <th>{t('longitude')}</th>
                <th>{t('location')}</th>
              </tr>
            </thead>
            <tbody>
              {heartbeats.map((device, i) => {
                return (
                  <tr>
                    <td>{device.devicename}</td>
                    <td>{moment(device.lastheartbeat).format('DD-MM-YYYY HH:mm:ss')}</td>
                    <td>{device.username}</td>
                    <td>{device.latitude}</td>
                    <td>{device.longitude}</td>
                    <td><i onClick={() => { createMap(device.devicename, device.username, device.latitude, device.longitude) }} className="fas fa-map-marked-alt"></i></td>
                  </tr>)
              })}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Heartbeats);