import React, { Component } from 'react';
import '../assets/style.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const NoAccess = () => {
  const { t } = useTranslation();
  
  return (
    <React.Fragment>
      <div className="noaccess">
        <img alt="no-access" src="/images/no-access-icon.png" />
        <div className="noaccess-text">
          <h2>{t('noaccess')}</h2>
          <p>{t('noaccesstext')}</p>
          <button className="btn-goback" onClick={() => { window.history.back(2); }}>{t('helpmeback')}</button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NoAccess;
