import React, { useEffect, useState } from 'react';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const AlarmsSidebar = () => {
    const { t } = useTranslation();
    const [alarms, setAlarms] = useState([]);
    const [countAlarms, setCountAlarms] = useState(0);
    const [showSidebar, setShowSidebar] = useState(false); // State to track sidebar visibility
    

    const getData = async () => {
        const functions = await import("../../Functions.js");
        const data = await functions.fetchApi("mba", "/alarm/get", "GET", {
            "locationid": localStorage.getItem('currentLocation')
        });
        var alarmcounter = 0;

        data.forEach(alarm => {
            alarmcounter++;
        });

        setCountAlarms(localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') != 0 ? alarmcounter : 0);
        setAlarms(localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') != 0 ? data : []);
    };

    const toggleSidebar = () => {
        setShowSidebar(prevState => !prevState); // Wissel de zichtbaarheid van de sidebar
        if (!showSidebar) {
                   // Get sidebars
        const teamsSidebar = document.querySelector(".teamsList");
        const alarmsSidebar = document.querySelector(".latest-alarms");
 
        // Hide every sidebar
        teamsSidebar.classList.remove('slide-in');
        alarmsSidebar.classList.remove('slide-in');
 
        // display the right sidebar
        alarmsSidebar.classList.add('slide-in');
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        // Scroll to top if the sidebar is opened
        if (showSidebar) {
            const sidebar = document.querySelector(".latest-alarms");
            sidebar.classList.add('slide-in');
            sidebar.scrollIntoView({ behavior: 'smooth' });
        } else {
            document.querySelector(".latest-alarms").classList.remove('slide-in');
        }
    }, [showSidebar]); // Trigger this effect when showSidebar changes

    


    return (
        <React.Fragment>
            <span className="header-icon alarmNotices" onClick={toggleSidebar}>
                <i className="fas fa-bell"></i>
                <span className="header-badge badge rounded-pill bg-danger">{countAlarms}</span>
            </span>

            <div className="latest-alarms">
                <div className="closeLatestAlarms" onClick={toggleSidebar}>
                    <i className="fas fa-times"></i>
                </div>

                <h6 className="latestAlarms_title"><i className="fas fa-users"></i> {t('alarms')}</h6>

                {!localStorage.getItem("currentLocation") || localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ?
                    <div style={{ display: localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ? "none" : "block" }} className="alert alert-warning msg_no_location" role="alert">
                        {t('location_not_selected')}
                    </div> : ''}

                {localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ?
                    alarms.map((alarm, i) => {
                        const StatusColor = alarm.date !== null && alarm.date !== '' && alarm.down !== null && alarm.down !== '' ? '#FFA500' : (alarm.finish !== null && alarm.finish !== '') ? 'rgb(92, 184, 92)' : '#FF0000';
                        
                        return (
                            <a key={i} href={`/alarm/${alarm.id}`}>
                                <div className="alarm" style={{ borderLeft: `solid 5px ${StatusColor}` }}>
                                    <strong>{t(`${alarm.slug}`)}</strong><br />
                                    {moment(alarm.date).format('DD-MM-YYYY HH:mm')}<br />
                                    {alarm.object}
                                </div>
                            </a>
                        );
                    })
                    : ''}

                {localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ? 
                    <a className="alarms-viewMore" href={`/alarms/open`}>{t('viewallalarms')}</a> : '' }
            </div>
        </React.Fragment>
    );
};

export default withRouter(AlarmsSidebar);
