import React, { useState, useEffect } from 'react';
import withAuth from '../../components/Login/withAuth';
import moment from 'moment';
import { confirmDelete, crudAlertControl, initDataTable, fetchApi } from '../../Functions';
import ModalDelete from '../../components/ModalDelete';
import 'flag-icon-css/css/flag-icons.min.css';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const Languages = () => {
    const { t } = useTranslation();
    const [languages, setLanguages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const getData = async () => {
        const data = await fetchApi("mba", "/mba/languages/get", "GET");
        setLanguages(data);
        initDataTable();
    }

    const addLanguage = async (event) => {
        event.preventDefault();
        const data = await fetchApi("mba", "/mba/languages/insert", "GET", {
            "code": event.target.code.value, "name": event.target.name.value, "countrycode": event.target.countrycode.value
        });
        
        if(data && data.length > 0){
            navigate('/manage/languages/' + data[0].id);
        }
    }

    const delLanguage = async (langid, langname) => {
        const data = await fetchApi("mba", `/mba/language/${langid}/delete`, "GET", {
            "langid": langid, "langname": langname
        });
        crudAlertControl('delete', data);
        await getData();
    }

    useEffect(() => {
        getData();
        
    }, [])

    return (
        <React.Fragment>

            <div className="alert alert-success alert-add" role="alert">
                {t('newlanguage')}
            </div>

            <div className="alert alert-success alert-delete" role="alert">
                {t('dellanguage')}
            </div>

            <div className="alert alert-danger" role="alert">
                {t('somethingwrong')}
            </div>

            <h2>{t('languages')}</h2>
            <div onClick={() => setShowModal(true)} className="btn btn-add">{t('add')}</div>

            <div className="DataTable_Container">
            <div className="table-responsive">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
                            <th>{t('country')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {languages.map((language, i) => {
                            return (
                                <tr key={i}>
                                    <td onClick={() => navigate("/manage/languages/" + language.id)}><span className={`flag-icon flag-icon-${language.country_code} mx-2`}></span> {language.name}</td>
                                    <td>
                                        <i onClick={(event) => confirmDelete(t('delitem'), function () { delLanguage(language.id, language.name) })} style={{ float: 'right', color: '#ff0000', marginRight: '15px', cursor: 'pointer' }} className="fas fa-trash-alt"></i>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
            </div>

            <ModalDelete />
                
            {showModal && ( 
                <div style={{ display: 'block' }} id="add" className="modal fade in">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('addlanguage')}</h4>
                            <div onClick={() => setShowModal(false)} className="close">&times;</div>
                        </div>

                        <div className="modal-body">
                            <form onSubmit={(event) => addLanguage(event)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="code">{t('code')}</label>
                                            <input type="text" name="code" id="code" className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="name">{t('name')}</label>
                                            <input type="text" name="name" id="name" className="form-control" required />
                                        </div>
                                        <div className="form-group">
                                            <label for="countrycode">{t('countrycode')}</label>
                                            <input type="text" name="countrycode" id="countrycode" className="form-control" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">{t('cancel')}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-green">{t('add')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default withAuth(Languages)